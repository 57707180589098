import React, { useState } from "react";
import Header from "../../components/Header";
import "./admin.scss";
import { useQuery } from "react-query";
import { getInvoices } from "../../api/invoiceApi";
import { Link } from "react-router-dom";
import { useJwtContext } from "../../hooks/useJwtContext";

function Admin() {
  const [inputQuery, setInputQuery] = useState("");
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState<"desc" | "asc">("desc");
  const { jwt, logOut } = useJwtContext();
  const invoicesQuery = useQuery({
    queryFn: () => getInvoices(page, query, order, jwt),
    queryKey: ["invoices", page, query, order],
  });
  return (
    <>
      <Header />
      <div className="wrapper">
        <h1 className="a_header">Panel administratora</h1>
        <div className="flex-out">
          <form
            className="search-input"
            onSubmit={(e) => {
              e.preventDefault();
              setQuery(inputQuery);
            }}
          >
            <input
              type="text"
              value={inputQuery}
              onChange={(e) => setInputQuery(e.target.value)}
            />
            <button type="submit">Wyszukaj</button>
          </form>
          <div>
            <button
              className="btn"
              type="submit"
              style={{ marginInlineEnd: "1rem" }}
              onClick={logOut}
            >
              Wyloguj
            </button>
            <select
              name="orderByDate"
              id="orderByDate"
              className="select"
              onChange={(e) => setOrder(e.target.value as any)}
            >
              <option value="desc">Najnowsze</option>
              <option value="asc">Najstarsze</option>
            </select>
          </div>
        </div>
        {invoicesQuery.data?.data.length ? (
          <table>
            <tr>
              <td>Model</td>
              <td>ID</td>
              <td>Rozmiar</td>
              <td>Cena</td>
              <td>Data</td>
              <td>Imię i nazwisko</td>
              <td></td>
            </tr>
            {invoicesQuery.data.data.map(
              ({ count, Invoice, model, price, size, id }) => {
                return (
                  <tr>
                    <td>{model}</td>
                    <td>{id.slice(0, 8)}</td>
                    <td>{size}</td>
                    <td>{`${price} ${Invoice.currency}`}</td>
                    <td>{Invoice.date.slice(0, 10)}</td>
                    <td>{`${Invoice.name} ${Invoice.lastname}`}</td>
                    <td>
                      <Link to={id}>Szczegóły</Link>
                    </td>
                  </tr>
                );
              }
            )}
          </table>
        ) : (
          "Brak danych!"
        )}

        <div className="flex-out">
          <button
            type="button"
            className="page-btn"
            onClick={() => setPage((p) => p - 1)}
            style={{ visibility: page > 1 ? "visible" : "hidden" }}
          >
            &lt;
          </button>
          <button
            type="button"
            onClick={() => setPage((p) => p + 1)}
            className="page-btn"
            style={{
              visibility:
                invoicesQuery.data?.data.length! > 1 ? "visible" : "hidden",
            }}
          >
            &gt;
          </button>
        </div>
      </div>
    </>
  );
}

export default Admin;
