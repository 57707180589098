import React, { useEffect } from "react";

type ModalProps = {
  isOpen: boolean;
  closeModal: Function;
  children: any;
};

function Modal({ isOpen, closeModal, children }: ModalProps) {
  function close(e: MouseEvent) {
    isOpen && closeModal();
  }
  useEffect(() => {
    window.addEventListener("click", close);
    return () => {
      window.removeEventListener("click", close);
    };
  });
  return isOpen ? <div className="modal">{children}</div> : <></>;
}

export default Modal;
