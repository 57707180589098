export type Language = {
  header: string;
  downloadLink: string;
  send: string;
  languageLabel: string;

  modal: {
    success: string;
    close: string;
  };

  personalData: {
    title: string;
    name: {
      title: string;
      placeholder: string;
    };
    lastname: {
      title: string;
      placeholder: string;
    };
    email: {
      title: string;
      placeholder: string;
    };
  };

  address: {
    title: string;
    country: {
      title: string;
      placeholder: string;
    };
    city: {
      title: string;
      placeholder: string;
    };
    postalCode: {
      title: string;
      placeholder: string;
    };
    street: {
      title: string;
      placeholder: string;
    };
    buildingAdress: {
      title: string;
      placeholder: string;
    };
    apartament: {
      title: string;
      placeholder: string;
    };
  };

  shoes: {
    title: string;
    add: string;
    remove: string;
    modelTitle: string;
    sizeTitle: string;
    priceTitle: string;
    quantityTitle: string;
  };

  others: {
    dateLabel: string;
    currencyLabel: string;
    signature: {
      label: string;
      reset: string;
    };
  };

  errors: {
    date: {
      required: string;
    };
    currency: {
      required: string;
      value: string;
    };
    name: {
      required: string;
      length: string;
    };
    lastname: {
      required: string;
      length: string;
    };
    email: {
      required: string;
      format: string;
    };
    zipCode: {
      required: string;
      length: string;
    };
    country: {
      required: string;
      length: string;
    };
    city: {
      required: string;
      length: string;
    };
    street: {
      required: string;
      length: string;
    };
    streetNumber: {
      required: string;
      length: string;
    };
    housingNumber: {
      length: string;
    };
    shoes: {
      length: string;
      shoe: {
        model: {
          required: string;
        };
        size: {
          required: string;
          min: string;
          max: string;
        };
        price: {
          required: string;
          length: string;
        };
        count: {
          required: string;
          length: string;
        };
      };
    };
  };
};

const languages: Record<Languages, Language> = {
  polish: {
    header: "UMOWA KUPNA SPRZEDAŻY",
    downloadLink: "Pobierz wzór umowy",
    send: "Wyślij",
    personalData: {
      title: "Dane sprzedającego",
      name: {
        title: "Imię",
        placeholder: "Jan",
      },
      lastname: {
        title: "Nazwisko",
        placeholder: "Kowalski",
      },
      email: {
        title: "Email",
        placeholder: "example@gmail.com",
      },
    },
    address: {
      title: "Adres",
      country: {
        title: "Kraj",
        placeholder: "Polska",
      },
      city: {
        title: "Miasto",
        placeholder: "Warszawa",
      },
      postalCode: {
        title: "Kod pocztowy (00-000)",
        placeholder: "12-345",
      },
      street: {
        title: "Ulica",
        placeholder: "Polna",
      },
      buildingAdress: {
        title: "Numer budynku",
        placeholder: "12",
      },
      apartament: {
        title: "Numer mieszkania (opcjonalne)",
        placeholder: "3",
      },
    },
    shoes: {
      add: "Dodaj",
      remove: "Usuń",
      modelTitle: "Model",
      sizeTitle: "Rozmiar",
      priceTitle: "Cena",
      quantityTitle: "Ilość par",
      title: "Buty",
    },
    others: {
      currencyLabel: "Waluta",
      dateLabel: "Data",
      signature: {
        label: "Podpis (użyj myszki)",
        reset: "Resetuj podpis",
      },
    },
    errors: {
      date: {
        required: "Data jest wymagana!",
      },
      currency: {
        required: "Waluta jest wymagana!",
        value: "Musisz wybrać EUR lub PLN!",
      },
      name: {
        required: "Imię jest wymagane!",
        length: "Imię musi mień min. 3 znaki!",
      },
      lastname: {
        required: "Nazwisko jest wymagane!",
        length: "Nazwisko musi mień min. 2 znaki!",
      },
      email: {
        required: "Email jest wymagany!",
        format: "Podano niepoprawny adres email!",
      },
      zipCode: {
        required: "Kod pocztowy jest wymagany!",
        length: "Podany kod pocztowy nie jest poprawny!",
      },
      country: {
        required: "Kraj jest wymagany!",
        length: "Kraj musi mieć min. 3 znaki!",
      },
      city: {
        required: "Miasto jest wymagane!",
        length: "Za krótka nazwa miasta!",
      },
      street: {
        required: "Ulica jest wymagana!",
        length: "Nazwa ulicy musi mieć min. 2 znaki!",
      },
      streetNumber: {
        required: "Numer budynku jest wymagany!",
        length: "Niepoprawny numer budynku!",
      },
      housingNumber: {
        length: "Niepoprawny numer mieszkania!",
      },
      shoes: {
        length: "Musisz dodać przynajmniej jedne buty!",
        shoe: {
          size: {
            required: "Rozmiar buta jest wymagany!",
            min: "Niepoprawny rozmiar buta!",
            max: "Niepoprawny rozmiar buta!",
          },
          price: {
            required: "Cena buta jest wymagana!",
            length: "Niepoprawna cena buta!",
          },
          count: {
            required: "Ilość par jest wymagana!",
            length: "Niepoprawna ilość par!",
          },
          model: {
            required: "Model buta jest wymagany!",
          },
        },
      },
    },
    modal: {
      success: "Wysłano umowę na maila!",
      close: "Kliknij aby zamknąć",
    },
    languageLabel: "Język",
  },
  english: {
    header: "SALE AND PURCHASE CONTRACT",
    downloadLink: "Download contract template",
    send: "Send",
    personalData: {
      title: "Personal data",
      name: {
        title: "Name",
        placeholder: "John",
      },
      lastname: {
        title: "Lastname",
        placeholder: "Smith",
      },
      email: {
        title: "Email",
        placeholder: "example@gmail.com",
      },
    },
    address: {
      title: "Address",
      city: {
        title: "City",
        placeholder: "London",
      },
      postalCode: {
        title: "ZIP code",
        placeholder: "27123",
      },
      street: {
        title: "Street",
        placeholder: "Hope Street",
      },
      buildingAdress: {
        title: "Building",
        placeholder: "12",
      },
      apartament: {
        title: "Apartament (optional)",
        placeholder: "2",
      },
      country: {
        title: "Country",
        placeholder: "Germany",
      },
    },
    shoes: {
      add: "Add",
      remove: "Remove",
      modelTitle: "Model",
      sizeTitle: "Size",
      priceTitle: "Price",
      quantityTitle: "Quantity",
      title: "Shoes",
    },
    others: {
      dateLabel: "Date",
      signature: {
        label: "Signature (use your mouse)",
        reset: "Reset signature",
      },
      currencyLabel: "Currency",
    },

    errors: {
      date: {
        required: "Date is required!",
      },
      currency: {
        required: "Currency is required!",
        value: "You must select EUR or PLN!",
      },
      name: {
        required: "Name is required!",
        length: "Too short name!",
      },
      lastname: {
        required: "Lastname is required!",
        length: "Too short lastname!",
      },
      email: {
        required: "Email is required!",
        format: "Invalid email!",
      },
      zipCode: {
        required: "ZIP code is required!",
        length: "Invalid ZIP code!",
      },
      country: {
        required: "Country is required!",
        length: "Too short country name!",
      },
      city: {
        required: "City is required!",
        length: "Too short city name!",
      },
      street: {
        required: "Street is required!",
        length: "Too short street name!",
      },
      streetNumber: {
        required: "Building address is required!",
        length: "Invalid building address!",
      },
      housingNumber: {
        length: "Invalid apartament address!",
      },
      shoes: {
        length: "You must add at least one pair!",
        shoe: {
          size: {
            required: "Shoe size is required!",
            min: "Invalid shoe size!",
            max: "Invalid shoe size!",
          },
          price: {
            required: "Shoe price is required!",
            length: "Invalid shoe size!",
          },
          count: {
            required: "Shoe quantity is required!",
            length: "Invalid shoe quantity!",
          },
          model: {
            required: "Shoe model is required!",
          },
        },
      },
    },
    modal: {
      success: "Contract sent successfully!",
      close: "Press to close",
    },
    languageLabel: "Language",
  },
};

export type Errors = Language["errors"];

export type Languages = "polish" | "english";

export default languages;
