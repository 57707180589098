import React from "react";
import Header from "../../components/Header";
import Form from "../../components/Form";
import FormWrapper from "../../components/FormWrapper";
import "./user.scss";

function User() {
  return (
    <main className="page-wrapper">
      <Header />
      <FormWrapper>
        <Form />
      </FormWrapper>
    </main>
  );
}

export default User;
