import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header";
import "../admin/admin.scss";
import { useMutation, useQuery } from "react-query";
import { getInvoice, regenerate, removeInvoice } from "../../api/invoiceApi";
import { useJwtContext } from "../../hooks/useJwtContext";

function InvoiceData() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { jwt } = useJwtContext();
  const [link, setLink] = useState("#");
  const { data } = useQuery({
    queryKey: ["invoice"],
    queryFn: () => getInvoice(id!, jwt),
  });

  const regen = useMutation({
    mutationKey: ["regenerate"],
    mutationFn: () => regenerate(id!, jwt),
    onSuccess: (data) => {
      const blob = new Blob([data.data as any]);
      const url = URL.createObjectURL(blob);
      setLink(url);
    },
  });

  const { mutate } = useMutation({
    mutationKey: ["deleteInvoice"],
    mutationFn: () => removeInvoice(id!, jwt),
    onSuccess: () => navigate("/admin"),
  });
  return (
    <>
      <Header />
      <div className="wrapper">
        <h1 className="a_header">
          Faktura: {id?.slice(0, 8)}
          <button
            className="btn"
            onClick={() => mutate()}
            style={{ marginInline: "2rem" }}
          >
            Usuń
          </button>
          <button className="btn-options" onClick={() => regen.mutate()}>
            Wygeneruj na nowo
          </button>
          <a
            download={`${data?.data.id}.pdf`}
            href={link}
            className="btn-options"
            style={{
              marginInline: "2rem",
              display: link === "#" ? "none" : "inline-block",
            }}
          >
            Pobierz
          </a>
        </h1>

        <p style={{ lineHeight: 1.5 }}>
          <strong>Klient:</strong> {`${data?.data.name} ${data?.data.lastname}`}{" "}
          <br />
          <strong>Email:</strong> {data?.data.email}
          <br />
          <strong>Kraj:</strong> {data?.data.country}
          <br />
          <strong>Miasto:</strong> {data?.data.city}
          <br />
          <strong>Kod pocztowy:</strong> {data?.data.postalCode}
          <br />
          <strong>Ulica: </strong>
          {`${data?.data.street} ${data?.data.streetNumber}`}
          {data?.data.housingNumber !== undefined
            ? `/${data.data.housingNumber}`
            : ""}
          <br />
          <strong>Data:</strong> {data?.data.date.substring(0, 10)}
        </p>
        <ul>
          Buty:
          {data?.data.shoes.map((shoe) => {
            return (
              <li style={{ marginLeft: "1rem" }} key={shoe.id}>
                {shoe.model}
                <ul style={{ marginLeft: "1rem" }}>
                  <li>Rozmiar: {shoe.size}</li>
                  <li>Ilość: {shoe.count}</li>
                  <li>Cena za parę: {shoe.price + " " + data.data.currency}</li>
                </ul>
              </li>
            );
          })}
        </ul>
        <Link to="../admin">Powrót</Link>
      </div>
    </>
  );
}

export default InvoiceData;
