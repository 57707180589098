import { createContext, useContext, useEffect, useState } from "react";

export type JwtContetx = {
  jwt?: string;
  setJwt: React.Dispatch<React.SetStateAction<string | undefined>>;
  logOut: () => void;
};

export const jwtContext = createContext<JwtContetx>({
  jwt: "",
  setJwt: (() => null) as React.Dispatch<
    React.SetStateAction<string | undefined>
  >,
  logOut: () => null,
});

function JwtContextProvider({ children }: { children: any }) {
  const [jwt, setJwt] = useState<string | undefined>("");
  const logOut = () => {
    localStorage.removeItem("jwt");
    setJwt("");
  };
  useEffect(() => {
    const savedJwt = localStorage.getItem("jwt");
    setJwt(savedJwt!);
  }, []);
  useEffect(() => {
    jwt && localStorage.setItem("jwt", jwt);
  }, [jwt]);
  return (
    <jwtContext.Provider
      value={{
        jwt,
        setJwt,
        logOut,
      }}
    >
      {children}
    </jwtContext.Provider>
  );
}

const useJwtContext = () => {
  return useContext(jwtContext);
};

export { useJwtContext, JwtContextProvider };
