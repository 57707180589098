import * as yup from "yup";
import { Errors } from "../langs";

export function createFormSchema({city,country,currency,date,email,housingNumber,lastname,name,shoes,street,streetNumber,zipCode}: Errors) {
  const formSchema = yup.object({
    date: yup.date().required(date.required),
    name: yup
      .string()
      .required(name.required)
      .min(3, name.length),
    lastname: yup
      .string()
      .required(lastname.required)
      .min(2, lastname.length),
    currency: yup
      .string()
      .required(currency.required)
      .oneOf(["EUR", "PLN"], currency.value),
    email: yup.string().required(email.required).email(email.format),
    postalCode: yup
      .string()
      .required(zipCode.required)
      .min(2, zipCode.length),
    country: yup
      .string()
      .required(country.required)
      .min(2, country.length),
    city: yup
      .string()
      .required(city.required)
      .min(2, city.length),
    street: yup
      .string()
      .required(street.required)
      .min(2, street.length),
    streetNumber: yup
      .string()
      .required(streetNumber.required)
      .min(1, streetNumber.length),
    housingNumber: yup.number().min(1, housingNumber.length),
    shoes: yup
      .array()
      .of(
        yup.object().shape({
          model: yup.string().required(shoes.shoe.model.required),
          size: yup
            .number()
            .required(shoes.shoe.size.required)
            .min(1, shoes.shoe.size.min)
            .max(70, shoes.shoe.size.max),
          price: yup
            .number()
            .required(shoes.shoe.price.required)
            .min(1, shoes.shoe.price.length),
          count: yup
            .number()
            .required(shoes.shoe.count.required)
            .min(1, shoes.shoe.count.length),
        })
      )
      .min(1, shoes.length),
  });
  return formSchema;
}
