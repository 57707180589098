import React, { useId } from "react";
import { Field, ErrorMessage } from "formik";
type FormFieldProps = {
  name: string;
  label: string;
  placeholder?: string;
  type?: string;
  width?: number;
  disabled?: boolean;
};

function FormField({
  name,
  label,
  placeholder,
  type,
  width,
  disabled,
}: FormFieldProps) {
  const id = useId();
  return (
    <div className="form__field">
      <label htmlFor={id} className="form__label">
        {label}
      </label>
      <p className="form__error">
        <ErrorMessage name={name} />
      </p>
      <Field
        name={name}
        id={id}
        placeholder={placeholder || ""}
        type={type || "text"}
        disabled={disabled}
      />
    </div>
  );
}

export default FormField;
