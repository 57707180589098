import axios, { AxiosResponse } from "axios";

const axiosClient = axios.create({
  // baseURL: "http://localhost:5000",
  baseURL: "http://128.140.87.189:5000",
});

export type Shoe = {
  model: string;
  size: string;
  price: string;
  count: string;
  id: string;
};

type Invoice = {
  id: string;
  date: string;
  name: string;
  lastname: string;
  email: string;
  postalCode: string;
  city: string;
  street: string;
  country: string;
  currency: string;
  streetNumber: string;
  housingNumber: string;
  shoes: Shoe[];
};

type ShoeWithInvoice = {
  Invoice: Invoice;
} & Shoe;

export async function createInvoice(data: unknown) {
  return axiosClient.post("/invoices", data, {
    headers: {
      "Content-Type": `multipart/form-data`,
    },
  });
}

export async function getInvoices(
  page: number,
  query: string,
  order: "asc" | "desc",
  jwt?: string
): Promise<AxiosResponse<ShoeWithInvoice[]>> {
  return axiosClient.get("/invoices", {
    params: {
      query,
      orderByDate: order,
      limit: 10,
      page,
    },
    headers: {
      authorization: `Bearer ${jwt}`,
    },
  });
}

export async function getInvoice(
  id: string,
  jwt?: string
): Promise<AxiosResponse<Invoice>> {
  return axiosClient.get("/invoices/" + id, {
    headers: {
      authorization: `Bearer ${jwt}`,
    },
  });
}

export async function login({
  login,
  password,
}: {
  login: string;
  password: string;
}): Promise<AxiosResponse<{ token: string }>> {
  return axiosClient.post("/login", {
    login,
    password,
  });
}

export async function removeInvoice(
  id: string,
  jwt?: string
): Promise<AxiosResponse<Invoice>> {
  return axiosClient.delete("/invoices/" + id, {
    headers: {
      authorization: `Bearer ${jwt}`,
    },
  });
}

export async function regenerate(
  id: string,
  jwt?: string
): Promise<AxiosResponse<Invoice>> {
  return axiosClient.get("/invoices/regenerate/" + id, {
    headers: {
      authorization: `Bearer ${jwt}`,
    },
    responseType: "arraybuffer",
  });
}
