import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import User from "./pages/user/User";
import Admin from "./pages/admin/Admin";
import InvoiceData from "./pages/invoice-data/InvoiceData";
import AdminPanelPage from "./pages/admin/AdminPanelAndLoginWrapper";

function App() {
  return (
    <Router>
      <Routes>
        <Route Component={User} index />
        <Route Component={AdminPanelPage} path="/admin" />
        <Route Component={InvoiceData} path="/admin/:id" />
      </Routes>
    </Router>
  );
}

export default App;
