import React, { useState, useRef } from "react";
import {
  Form as FormikForm,
  Formik,
  FieldArray,
  Field,
  ErrorMessage,
} from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FormField from "./FormField";
import { createFormSchema } from "./formSchema";
import SignatureCanvas from "react-signature-canvas";
import dataURLtoBlob from "blueimp-canvas-to-blob";
import { useMutation } from "react-query";
import { createInvoice } from "../api/invoiceApi";
import Modal from "./Modal";
import { useLanguageContext } from "../hooks/useLanguageContext";
import { Languages } from "../langs";

const initialValues = {
  date: new Date(),
  name: "",
  lastname: "",
  email: "",
  city: "",
  postalCode: "",
  street: "",
  streetNumber: "",
  housingNumber: "",
  currency: "EUR",
  shoes: [],
};

// const initialValues = {
//   date: new Date(),
//   name: "Jan",
//   lastname: "Kowalski",
//   email: "jjsternik@gmail.com",
//   city: "Kabiczyce",
//   country: "Polska",
//   currency: "EUR",
//   postalCode: "12-345",
//   street: "Mała",
//   streetNumber: "77",
//   housingNumber: "4",
//   shoes: [
//     {
//       model: "AirForce One",
//       size: 39,
//       price: 599.99,
//       count: 1,
//     },
//     {
//       model: "Wowshoes Ten",
//       size: 50,
//       price: 1122,
//       count: 3,
//     },
//   ],
// };

export default function Form() {
  const [inputDate, setInputDate] = useState(new Date());
  const [isModal, setIsModal] = useState(false);
  const cavnasRef = useRef<any>();
  const { changeLanguage, language } = useLanguageContext();
  const formSchema = createFormSchema(language.errors);
  const mutation = useMutation({
    mutationFn: (data) => createInvoice(data),
    onSuccess: () => {
      setIsModal(true);
    },
    onError: (error) => {
      console.log(error);
    },
  });
  return (
    <div className="form">
      <Modal
        isOpen={isModal}
        closeModal={() => {
          setIsModal(false);
          window.location.reload();
        }}
      >
        <h2 className="modal__header">{language.modal.success}</h2>
        <p>{language.modal.close}</p>
      </Modal>
      <h1 className="form__header--main">{language.header}</h1>
      <label htmlFor="lang">{language.languageLabel}</label>
      <select
        name="lang"
        id="lang"
        onChange={(e) => changeLanguage(e.target.value as Languages)}
        className="form__input"
      >
        <option value="english">English &#x1F1FA;&#x1F1F8;</option>
        <option value="polish">Polski &#x1F1F5;&#x1F1F1;</option>
      </select>
      <p
        style={{
          marginBottom: "1rem",
        }}
      >
        <a href="/wzór_umowy.pdf" download>
          {language.downloadLink}
        </a>
      </p>
      <Formik
        validationSchema={formSchema}
        initialValues={initialValues}
        onSubmit={(v) => {
          if (!cavnasRef.current!.isEmpty()) {
            const data = new window.FormData();
            const blob = dataURLtoBlob(
              cavnasRef.current.toDataURL("image/png")
            );
            const json = JSON.stringify(v);
            data.append("json", json);
            data.append("signature", blob, "signature.png");
            mutation.mutate(data as any);
          }
        }}
      >
        {({ setFieldValue, values, errors }) => (
          <FormikForm>
            <div className="form__group">
              <h2 className="form__header">{language.personalData.title}</h2>
              <FormField
                name="name"
                label={language.personalData.name.title}
                placeholder={language.personalData.name.placeholder}
                disabled={mutation.isLoading}
              />
              <FormField
                name="lastname"
                label={language.personalData.lastname.title}
                placeholder={language.personalData.lastname.placeholder}
                disabled={mutation.isLoading}
              />
              <FormField
                name="email"
                label={language.personalData.email.title}
                placeholder={language.personalData.email.placeholder}
                disabled={mutation.isLoading}
              />
            </div>
            <div className="form__group">
              <h2 className="form__header">{language.address.title}</h2>
              <FormField
                name="country"
                label={language.address.country.title}
                placeholder={language.address.country.placeholder}
                disabled={mutation.isLoading}
              />
              <FormField
                name="city"
                label={language.address.city.title}
                placeholder={language.address.city.placeholder}
                disabled={mutation.isLoading}
              />
              <FormField
                disabled={mutation.isLoading}
                name="postalCode"
                label={language.address.postalCode.title}
                placeholder={language.address.postalCode.placeholder}
              />
              <FormField
                name="street"
                label={language.address.street.title}
                placeholder={language.address.street.placeholder}
                disabled={mutation.isLoading}
              />
              <FormField
                disabled={mutation.isLoading}
                name="streetNumber"
                label={language.address.buildingAdress.title}
                placeholder={language.address.buildingAdress.placeholder}
                type="string"
              />
              <FormField
                disabled={mutation.isLoading}
                name="housingNumber"
                label={language.address.apartament.title}
                placeholder={language.address.apartament.placeholder}
                type="number"
              />
            </div>

            <div className="form__group">
              <div className="form__field">
                <label htmlFor="currency" className="form__label">
                  {language.others.currencyLabel}
                </label>
                <p className="form__error">
                  <ErrorMessage name="currency" />
                </p>
                <Field
                  as="select"
                  name="currency"
                  id="currency"
                  className="form__input"
                >
                  <option value="EUR">EUR - Euro &euro;</option>
                  <option value="PLN">PLN - Polska - zł</option>
                </Field>
              </div>
            </div>
            <div className="form__group">
              <FieldArray
                name="shoes"
                render={({ push, remove }) => (
                  <>
                    <h2 className="form__header">
                      {language.shoes.title}
                      <button
                        onClick={() => {
                          !mutation.isLoading &&
                            push({
                              model: "",
                              size: null,
                              price: null,
                              count: 1,
                            });
                        }}
                        style={{
                          marginLeft: "2rem",
                        }}
                        type="button"
                        className="form__button form__button--small"
                        disabled={mutation.isLoading}
                      >
                        {language.shoes.add} <i className="bx bx-plus"></i>
                      </button>
                    </h2>

                    {values.shoes.length > 0 ? (
                      values.shoes.map((shoe, index) => (
                        <div key={index}>
                          <FormField
                            disabled={mutation.isLoading}
                            name={`shoes[${index}].model`}
                            label={language.shoes.modelTitle}
                          />
                          <FormField
                            disabled={mutation.isLoading}
                            name={`shoes[${index}].size`}
                            label={language.shoes.sizeTitle}
                            type="number"
                          />
                          <FormField
                            disabled={mutation.isLoading}
                            name={`shoes[${index}].price`}
                            label={`${language.shoes.priceTitle} (${values.currency})`}
                            type="number"
                          />
                          <FormField
                            disabled={mutation.isLoading}
                            name={`shoes[${index}].count`}
                            label={language.shoes.quantityTitle}
                            type="number"
                          />
                          <button
                            className="form__button form__button--small form__button--danger"
                            type="button"
                            disabled={mutation.isLoading}
                            onClick={() => {
                              !mutation.isLoading && remove(index);
                            }}
                          >
                            {language.shoes.remove}{" "}
                            <i className="bx bxs-trash-alt"></i>
                          </button>
                          <div className="form__divider"></div>
                        </div>
                      ))
                    ) : (
                      <p className="form__error">
                        {language.errors.shoes.length}
                      </p>
                    )}
                  </>
                )}
              />
            </div>
            <div className="form__group">
              <label htmlFor="date" className="form__label">
                {language.others.dateLabel}
              </label>
              <DatePicker
                disabled={mutation.isLoading}
                className="form__input"
                placeholderText="Wybierz date..."
                dateFormat="dd/MM/yyyy"
                selected={inputDate}
                id="date"
                popperProps={{
                  strategy: "fixed",
                }}
                onSelect={(d) => {
                  setFieldValue("date", d, false);
                  setInputDate(d);
                }}
                onChange={(d) => {
                  setFieldValue("date", d, false);
                  setInputDate(d as any);
                }}
              />
              <label htmlFor="canvas" className="form__label">
                {language.others.signature.label}
              </label>
              <SignatureCanvas
                penColor="black"
                ref={cavnasRef as any}
                canvasProps={{
                  id: "canvas",
                  className: "form__canvas",
                  style: mutation.isLoading
                    ? {
                        border: "2px solid #575252",
                      }
                    : {},
                }}
              />
            </div>
            <button
              type="button"
              className="form__button form__button--secondary"
              style={{
                marginRight: "1rem",
              }}
              onClick={() => {
                cavnasRef.current!.clear();
              }}
            >
              {language.others.signature.reset}
            </button>
            <button
              type="submit"
              className="form__button"
              disabled={mutation.isLoading}
            >
              {language.send}
            </button>
          </FormikForm>
        )}
      </Formik>
    </div>
  );
}
