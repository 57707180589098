import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { QueryClient, QueryClientProvider } from "react-query";
import { LanguageContextProvider } from "./hooks/useLanguageContext";
import "./assets/style.scss";
import { JwtContextProvider } from "./hooks/useJwtContext";
export const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <LanguageContextProvider>
      <JwtContextProvider>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </JwtContextProvider>
    </LanguageContextProvider>
  </React.StrictMode>
);
