import React, { ReactNode } from "react";

type FormWrapperProps = {
  children: ReactNode;
};

function FormWrapper({ children }: FormWrapperProps) {
  return <div className="form-wrapper">{children}</div>;
}

export default FormWrapper;
