import React from "react";
import image from "../assets/logo.jpg";
function Header() {
  return (
    <header className="header">
      <a href="https://sneakerman.store/">
        <img src={image} alt="Logo" className="header__image" />
      </a>
    </header>
  );
}

export default Header;
