import { createContext, useContext, useState, useCallback } from "react";
import languages, { Languages, Language } from "../langs";
const languageContext = createContext({
  changeLanguage: (lang: Languages) => {},
  language: {} as Language,
});

function LanguageContextProvider({ children }: { children: any }) {
  const [language, setLanguage] = useState(languages["english"]);
  const changeLanguage = useCallback(
    (lang: Languages) => {
      setLanguage(languages[lang]);
    },
    [setLanguage]
  );
  return (
    <languageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </languageContext.Provider>
  );
}

const useLanguageContext = () => {
  return useContext(languageContext);
};

export { languageContext, useLanguageContext, LanguageContextProvider };
