import React from "react";
import { Formik, Form } from "formik";
import { login as loginFunc } from "../../api/invoiceApi";
import { useMutation } from "react-query";
import { AxiosError } from "axios";
import FormField from "../../components/FormField";
import { loginFormSchema } from "./loginFormSchema";
import { useJwtContext } from "../../hooks/useJwtContext";

export function Login() {
  const { setJwt } = useJwtContext();
  const mutation = useMutation({
    mutationFn: ({ login, password }: { login: string; password: string }) =>
      loginFunc({ login, password }),
    onError: (error: AxiosError) => {
      console.log(error.message);
    },
    onSuccess: (data) => {
      setJwt(data.data.token);
    },
  });

  //   <div
  //   className="form-wrapper"
  //   style={{
  //     marginTop: "2rem",
  //   }}
  // >
  // </div>

  return (
    <div
      className="form-wrapper"
      style={{
        marginTop: "2rem",
      }}
    >
      <div className="form">
        <div className="form__group">
          <h1 className="form__header--main">Panel administratora</h1>
        </div>
        <Formik
          initialValues={{ login: "", password: "" }}
          onSubmit={(data) => {
            mutation.mutate({ ...data });
          }}
          validationSchema={loginFormSchema}
        >
          <Form>
            <div className="form__group">
              <h2 className="form__header">Logowanie</h2>
              <FormField
                name="login"
                label="Login"
                placeholder=""
                disabled={mutation.isLoading}
              />
              <FormField
                disabled={mutation.isLoading}
                name="password"
                label="Hasło"
                placeholder=""
                type="password"
              />
              {mutation.isError ? (
                <p className="form__error">Niepoprawne login lub hasło!</p>
              ) : null}
              <button
                className="form__button form__button--small"
                type="submit"
              >
                Zaloguj się
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
}

export default Login;
